/* You can add global styles to this file, and also import other style files */

/* Full calendar stuff */
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import '~@fullcalendar/list/main.css';

/* This stuff is used for displaying the Full Calender events properly */
.fc-event {
    background-color: unset; /* screw you and your default color */
    border-color: transparent !important;
    border-width: 1px !important;
    border-style: solid !important;
}

.anytimeQuotes  .fc-event {
    border: 0px !important;
}

.fc-title {
    font-size: .95em;
}

td.fc-day.fc-past {
    background-color: #EEEEEE;
}

.fc-bgevent {
    background-color: #d7d7d7 !important;
}

.fc-event-dot {
    height: 20px;
    width: 20px;
    border-radius: 10px;
    vertical-align: middle;
}

.fc-more-info {
    color: rgb(0, 122, 217);
}

.fc-edit-info {
    color: rgb(0, 122, 217);
}

/* These styles overwrite the styles from primeng so that the added components
    match the look they want. Mostly changing the colors to grey and getting
    rid of colorful shadows or borders. */

body .ui-tabview.ui-tabview-top .ui-tabview-nav li {
    background-color: #b7b7b7;
    border: 1px solid #b7b7b7;
}

body .ui-tabview.ui-tabview-top .ui-tabview-nav li:focus {
    outline: -webkit-focus-ring-color auto 0px;
}

body .ui-tabview.ui-tabview-top .ui-tabview-nav li:hover {
    border: 1px #b7b7b7;
    background-color: #b7b7b7;
}

body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active {
    background-color: #ebebeb;
    border: 1px solid #ebebeb;
}

.ui-tabview-title {
    color: black;
}

body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active:hover {
    border: 1px #ebebeb;
    background-color: #ebebeb;
}

body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active:focus {
    outline: -webkit-focus-ring-color auto 0px;
}

body .ui-tabview .ui-tabview-panels {
    border-top: 1px solid #c8c8c8;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 0px;
}

body .ui-menubar {
    border: 0px;
}

body .topHeader .ui-panel {
    margin-bottom: 20px;
}

body .topHeader .ui-panel .ui-panel-titlebar .ui-panel-title {
    font-weight: bold;
    text-transform: uppercase;
}

body .innerHeader .ui-panel .ui-panel-titlebar .ui-panel-title {
    font-weight: normal;
    font-style: italic;
}

body .ui-panel .ui-panel-titlebar {
    text-align: center;
    border: 0px;
    background-color: white;
}

body .ui-panel .ui-panel-content {
    border: 0px;
}

body .ui-calendar.ui-calendar-w-btn .ui-datepicker-trigger.ui-button {
    background-color: #ebebeb;
    border: 1px solid #a6a6a6;
    color: #a6a6a6;
}

body .ui-button:enabled:focus {
    box-shadow: 0 0 0 0;
}

body .match-height-card .ui-card {
    height: 100%;
}

body .ui-card {
    text-align: center;
    background-color: #ebebeb;
}

/* The bottom of the WO card needs to stretch all the way across the card.  */
body .wo-card .ui-card-body {
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

body .wo-card.multiday .ui-card {
    background-color: #9ee6a9;
}

body .wo-card .toggle-more-information {
    padding: 3px 10px;
}

body .crew-card .ui-card,
body .bucket-card .ui-card {
    text-align: left;
}

body .ui-corner-all {
    border-radius: 15px;
}

/* The dropdown spazzes with border-radius at 15px */
body .ui-dropdown {
    border-radius: 5px;
}

.ui-dropdown .ui-dropdown-panel {
    border-radius: 5px;
}

body .ui-card-header {
    font-weight: bold;
    padding: 5px;
    padding-bottom: 0px;
}

body .progress {
    background-color: #a6a6a6;
    font-style: italic;
    text-align: center;
    border-radius: 15px;
}

.ui-float-label {
    margin-top: 10px;
    margin-bottom: 10px;
}

.filterBar.dayView {
    border-bottom: 1px solid #a6a6a6; /* underline below the "search" and filters */

    margin-top: -20px;
    margin-bottom: 10px;

    display: flex;
    justify-content: space-between;

}

/* This hides the bottom of the "search" that was poking out*/
.filterBar span input {
    border: none;
}

.filterBar.dayView .filterBoxContainer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    /* flex-direction: row; */
    flex-wrap: wrap;

}
.filterBar.dayView .ui-float-label {
    float:left;
}
.filterBox.dayView {
    display: inline;
}

.filterBox button {
    border: none;
    background: none;
}

.filterBox.dayView button {
    border-right: 1px solid #a6a6a6;
}

/* It's first of type cuz they're in reverse order */
.filterBox.dayView:last-of-type > *{
    border-right: none;
}

.filterBox .alert {
    margin-top: 5px;
}

.filterBox .ui-multiselected-item-token,
.filterBox .ui-multiselected-empty-token {
    padding: 2px 4px;
    margin: 0 0.286em 0 0;
    display: inline-block;
    vertical-align:bottom;
    height: 1.957em;
}

.filterBox .ui-multiselect {
    height: 25px;
}

.filterBox .ui-multiselect .ui-multiselect-label {
    padding-top: 2px;
    padding-bottom: 0;
    margin-top: 5px;
}

.filterBox .ui-multiselect-label-container {
    height: 25px;
    margin-top: -8px;
}

.filterBox .ui-multiselect .ui-multiselect-trigger {
    background-color: unset;
}

.filterBox:not(.dayView) {
    text-align: center;
}

.filterBox:not(.dayView) button {
    padding-top: 10px;
    padding-bottom: 10px;
}

.filterBox:not(.dayView):before {
    content: ""; /* This is necessary for the pseudo element to work. */
    display: block; /* This will put the pseudo element on its own line. */
    margin: 0 auto; /* This will center the border. */
    width: 80%; /* Change this to whatever width you want. */
    border-bottom: 1px solid #cfcfcf; /* This creates the border. Replace black with whatever color you want. */
}

.filterBar:not(.dayView) .ui-float-label {
    display: block;
    text-align: center;
    margin-bottom: 10px;
}

.filterBar:not(.dayView) .ui-float-label>label {
    left:17%;
}
.filterBar:not(.dayView) .float-input {
    margin-bottom: -999px;
    width:100%;
}

.float-input {
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-radius: 5px; /* This looked ridiculous with 15px radius */
}

body .card-footer {
    padding: 1px;
    font-style: italic;
    font-size: 10px;
}

body .wo-card .ui-card-header {
    color: white;
    padding: 0px;
}

body div #twoDayCalendar .bucket-card .ui-card {
    margin-bottom: 20px;
}

body #twoDayCalendar p-card > .ui-card {
    background-color: #fbfbfb;
}

.scheduled-item div .ui-card-body {
    background-color: #ebebeb;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
}

.scheduled-item .my-schedule-card .ui-card-body {
    background-color: #ebebeb;
    border-radius: 15px;
}

body .scheduled-item .inner-card .ui-card-body {
    background-color: white;
    border-radius: 15px;
}

.crew-card .ui-card-body,
.employee-card .ui-card-body,
.equipment-card .ui-card-body,
.wo-card .ui-card-body {
    padding: 3px 5px;
    border-radius: 10px;
}

.inner-card .inner-card .ui-card-body {
    padding: 3px 5px;
}

body div #twoDayCalendar .bucket-card .inner-card {
    margin: 0px;
}

body #twoDayCalendar .ui-card-header {
    background-color: #ebebeb;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

body div #fiveDayCalendar .bucket-card .inner-card {
    margin: 0px;
}

body #fiveDayCalendar .ui-card-header {
    background-color: #ebebeb;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

body div #fiveDayCalendar .bucket-card .ui-card {
    margin-bottom: 20px;
}

body #fiveDayCalendar p-card > .ui-card {
    background-color: #fbfbfb;
}

body #twoDayCalendar #unavaiable-eqp-and-emps p-card > .ui-card {
    background-color: rgb(201, 201, 201);
    padding: 4px;
}
body #fiveDayCalendar #unavaiable-eqp-and-emps p-card > .ui-card {
    background-color: rgb(201, 201, 201);
    padding: 4px;
}

/* Inner cards should be white since the outer card is grey */
body .inner-card .ui-card {
    background-color: white;
    text-align: center;
    margin-bottom: 0px !important;
}

.inner-card {
    padding-bottom: 5px;
}

/* So if you have an inner card within an inner card, then the background should be grey since inner-card's background is white */
.inner-card .inner-card .ui-card {
    background-color: #ebebeb;
}

.scheduled-item .inner-card .inner-card .ui-card-body {
    background-color: #ebebeb;
}

body .crew-invalid > .ui-card {
    background-color: red;
    border-radius: 15px;
}

body .crew-invalid > .ui-card > .ui-card-body {
    background-color: red;
    border-radius: 15px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
}

body #twoDayCalendar .crew-invalid .ui-card-header {
    background-color: red;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

body #fiveDayCalendar .crew-invalid .ui-card-header {
    background-color: red;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

textarea.form-control.ng-touched.ng-invalid {
    border-color: red;
}

/* Placeholder font was too big */
::-webkit-input-placeholder {
    font-size: 14px;
}

::-moz-placeholder {
    font-size: 14px;
}

::-ms-input-placeholder {
    font-size: 14px;
}

:-ms-input-placeholder {
    font-size: 14px;
}

::placeholder {
    font-size: 14px;
}

.grayOverlay {
    opacity: 0.4;
    filter: alpha(opacity=40); /* msie */
    background-color: #000;
    border-radius: 15px;
    z-index: 100000;
    cursor: not-allowed;
}

.grayOverlay > .ui-card {
    opacity: 0.8;
    filter: alpha(opacity=80); /* msie */
    background-color: #000;
    -webkit-filter: grayscale(80%);
    -moz-filter: grayscale(80%);
    -o-filter: grayscale(80%);
    -ms-filter: grayscale(80%);
    filter: grayscale(80%);
}

#workOrdersPanelList .grayOverlay {
    background-color: #AAA;
}

#workOrdersPanelList .grayOverlay .wo-header {
    opacity: 0.4;
}

#workOrdersPanelList .grayOverlay > .ui-card {
    background-color: #AAA;
    -webkit-filter: grayscale(30%);
    -moz-filter: grayscale(30%);
    -o-filter: grayscale(30%);
    -ms-filter: grayscale(30%);
    filter: grayscale(30%);
}

#workOrdersPanelList .grayOverlay > .ui-card .inner-card .ui-card {
    background-color: #CCC;
}

body .taskGrayOverlay .ui-card {
    background-color: #BBB;
    opacity: 0.6;
    cursor: not-allowed;
}

body .complete {
    -webkit-filter: grayscale(80%);
    -moz-filter: grayscale(80%);
    -o-filter: grayscale(80%);
    -ms-filter: grayscale(80%);
    filter: grayscale(80%);
    opacity: .6;
}

body .cdk-drag-preview {
    border-radius: 15px !important;
}

.cdk-drag-placeholder {
    border-radius: 15px !important;
}

.drag-placeholder {
    border-radius: 15px;
    opacity: 0.7;
    filter: alpha(opacity=70); /* msie */
}

.dateInTable input {
    width:100%;
}

/* get rid of dropdown funny edge on the right */
.ui-dropdown-trigger.ui-corner-right {
     border-radius: 9px; /* just needs to be larger than ~4px */
}

body .ui-inputtext.ng-dirty.ng-invalid,
body ng-select.ng-dirty.ng-invalid > .ng-select-container,
body p-autocomplete.ng-dirty.ng-invalid > .ui-autocomplete > .ui-inputtext,
body p-calendar.ng-dirty.ng-invalid > .ui-calendar > .ui-inputtext,
body p-chips.ng-dirty.ng-invalid > .ui-inputtext,
body p-inputmask.ng-dirty.ng-invalid > .ui-inputtext,
body p-checkbox.ng-dirty.ng-invalid .ui-chkbox-box,
body p-radiobutton.ng-dirty.ng-invalid .ui-radiobutton-box,
body p-inputswitch.ng-dirty.ng-invalid .ui-inputswitch,
body p-listbox.ng-dirty.ng-invalid .ui-inputtext,
body p-multiselect.ng-dirty.ng-invalid > .ui-multiselect,
body p-spinner.ng-dirty.ng-invalid > .ui-inputtext,
body p-selectbutton.ng-dirty.ng-invalid .ui-button,
body p-togglebutton.ng-dirty.ng-invalid .ui-button,
body .ui-inputtext.ng-touched.ng-invalid,
body p-dropdown.ng-touched.ng-invalid > .ui-dropdown,
body p-autocomplete.ng-touched.ng-invalid > .ui-autocomplete > .ui-inputtext,
body p-calendar.ng-touched.ng-invalid > .ui-calendar > .ui-inputtext,
body p-chips.ng-touched.ng-invalid > .ui-inputtext,
body p-inputmask.ng-touched.ng-invalid > .ui-inputtext,
body p-checkbox.ng-touched.ng-invalid .ui-chkbox-box,
body p-radiobutton.ng-touched.ng-invalid .ui-radiobutton-box,
body p-inputswitch.ng-touched.ng-invalid .ui-inputswitch,
body p-listbox.ng-touched.ng-invalid .ui-inputtext,
body p-multiselect.ng-touched.ng-invalid > .ui-multiselect,
body p-spinner.ng-touched.ng-invalid > .ui-inputtext,
body p-selectbutton.ng-touched.ng-invalid .ui-button,
body p-togglebutton.ng-touched.ng-invalid .ui-button {
    border: 2px solid #f00000;
}

/* Code sardines! You found Brian Davis' hiding spot. If you find this, add your name here too. Josh Lind */

.headerText {
    font-weight: bolder;
    font-size: 1.3em;
}

.headerToggleDiv {
    display: inline-block;
}
.headerToggleDiv > p-checkbox{
    margin-left: 3px;
    margin-right: 20px;
}

.ui-g > .ui-g-12 > input {
    width: 100%;
}

@media screen and (min-width: 64.063em) {
    .ui-lg-88-percent {
        width: 88%;
    }
    .ui-lg-78-percent {
        width: 78%;
    }
    .ui-lg-44-percent {
        width: 44%;
    }
    .ui-lg-30-percent {
        width: 30%;
    }
    .ui-lg-22-percent {
        width: 22%;
    }
    .ui-lg-12-percent {
        width: 12%;
    }
    .ui-lg-10-percent {
        width: 10%;
    }
}

.pushContentsToBottom {
    display:flex;
    justify-content:flex-end;
    align-items:flex-end;
}

.ui-toast, 
.ui-confirmdialog {
    /* Toast/confirm dialog should always be on top, even on top of dialogs */
    z-index: 9999999 !important;
}

body .ui-menubar .ui-menuitem-link {
    border-radius: 15px;
}

span.clear { clear: right; display: block; }

body .editButtonMargin {
    margin-right: 5px;
    margin-bottom: 0px;
}

@media only screen and (max-width: 1440px) {
    body .editButtonMargin {
        margin-bottom: 5px;
    }
}

body .blackBorder {
    background-color: gray;
    border: 15px;
}

body .addNewButton {
    margin-bottom: 12px;
}
span.clear { clear: right; display: block; }

.scheduleButton {
    width: 100%;
    margin-bottom: 10px !important;
}

/** Don't make all dialogs scrollable because it looks weird if there isn't enough content that it needs to scroll **/
body .scrollableDialog .ui-dialog {
    overflow-y: scroll;
    max-height: 90%;
}

body .editMargin {
    margin-right: 2px;
    margin-bottom: 0px;
}

@media only screen and (max-width: 1200px) and (min-width: 1023px) {
    body .editMargin {
        margin-right: 0px;
        margin-bottom: 2px;
    }
}

/** Phones **/
@media only screen
  and (min-width: 320px)
  and (max-width: 812px) {
    body .editMargin {
        margin-right: 0px;
        margin-bottom: 2px;
    }
}

/** Custom styling for ng-select so it fits in with primeng better **/
body .ng-select.custom {
    min-height: 0px;
}
body .ng-select-disabled {
    opacity: 0.5;
}
body .ng-select.custom .ng-select-container  {
    border-radius: 5px;
    border: 1px solid #a6a6a6;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}
/** End custom styling for ng-select **/

/* leave padding as is for inner tables*/
.innerMaintenanceTable .ui-table .ui-table-tbody>tr>td.ui-editing-cell {
    padding: 0.571em 0.857em;
}

.lessTablePad .ui-table .ui-table-tbody>tr>td,
.lessTablePad .ui-table .ui-table-thead > tr > th {
    padding: 0.57em 0.5em;
}

.scheduleButton {
    width: 100%;
    margin-bottom: 10px !important;
}

@media only screen and (min-width: 40.063em) {
    body .left-this > .ui-calendar .ui-datepicker {
        left: -140px !important;
    }
}

body .priority-calendar-picker > .ui-calendar .ui-datepicker {
    min-width: 350px !important;
}

body .ui-inputswitch.ui-inputswitch-checked .ui-inputswitch-slider {
    background-color: #34A835 !important;
}

body .ui-table .ui-table-tbody > tr.ui-state-highlight {
    background-color: #34A835 !important;
    color: #ffffff;
}

body .ui-table .ui-table-tbody > tr.ui-state-highlight .ui-chkbox .ui-chkbox-box.ui-state-active {
    border-color: #fff;
    background-color: #34A835;
    color: #fff;
}

body .ui-button-green {
    background-color: rgb(0, 128, 24);
    border-color: rgb(0, 128, 23);
}

body .ui-button-green:active {
    background-color: rgb(66, 145, 81) !important;
}

body .ui-button-green:hover {
    background-color: rgb(66, 145, 81) !important;
}

body .ui-button-blue {
    background-color: rgb(8, 80, 144);
}

body .quotesFieldset > .ui-fieldset > .ui-fieldset-legend {
    font-size: 15px;
    width: 50%;
}

body .ui-dialog-content > .ui-icon {
    display: none !important;
}

body.busy-cursor {
    cursor: progress;
}

body .ui-multiselect {
    border-radius: 5px !important;
    vertical-align: middle;
}

body .ui-tabview-left-icon {
    color: black !important;
}

.fine-print {
    font-size: small;
    font-style: italic;
}

body .ui-accordion .ui-accordion-header a {
    background-color: unset;
    border: 0px;
    border-bottom: 1px;
    color: black;
}

body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active a {
    background-color: unset;
    border: 0px;
    color: black;
}

body .ui-accordion .ui-accordion-header a .ui-accordion-toggle-icon {
    color: #aeaeae;
}

body .ui-accordion .ui-accordion-content {
    border: 0px;
    border-color: #c1c1c1;
}

body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active:hover a {
    background-color: unset;
    border: 0px;
    color: black;
}

body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active:active a {
    background-color: unset;
    border: 0px;
    color: black;
}

body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active a .ui-accordion-toggle-icon {
    color: #aeaeae;
}

body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active:hover a .ui-accordion-toggle-icon {
    color: #aeaeae;
}



body .ui-accordion .ui-accordion-header:not(.ui-state-active):not(.ui-state-disabled):hover a {
    background-color: unset;
    border: 0px;
    color: black;
}

body .ui-accordion .ui-accordion-header:not(.ui-state-active):not(.ui-state-disabled):active a {
    background-color: unset;
    border: 0px;
    color: black;
}

body .ui-accordion .ui-accordion-header:not(.ui-state-active):not(.ui-state-disabled) a .ui-accordion-toggle-icon {
    color: #aeaeae;
}

body .ui-accordion .ui-accordion-header:not(.ui-state-active):not(.ui-state-disabled):hover a .ui-accordion-toggle-icon {
    color: #aeaeae;
}

:focus {outline:0 !important;}

.ui-accordion .ui-accordion-toggle-icon, .ui-accordion .ui-accordion-header-text {
    font-size: 20px;
}

.link {
    color: #34A835 !important;
    text-decoration: underline !important;
    font-weight: bold;
    cursor: pointer;
}

.regular-link {
    color: #34A835 !important;
    text-decoration: none !important;
    cursor: pointer;
}

.disabled-link {
    color: lightslategrey !important;
    text-decoration: none !important;
    cursor: not-allowed;
}

.blue-link {
    color: #3a8cd8 !important;
    text-decoration: none !important;
    cursor: pointer;
}

.checkout {
    display: block;
    margin: -1em;
}

.input-group-icon-btn {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border: 1px solid #a6a6a6 !important;
}

.sigPadWrapper>canvas {
    border: #DDD dashed 1px;
}

.hide-calendar-input span input {
    display: none;
}

/* Datepicker to fit on small mobile */
@media (max-width: 400px) {
    .hide-calendar-input span .ui-datepicker {
        padding-left: 0px;
        padding-right: 0px;
    }
 }

body .ui-message-error {
    margin: .5em !important;
  }

.showLineBreaks {
    white-space: pre-line;
    word-break: break-word;
}

.my-menu.ui-menubar .ui-menubar-custom {
  padding: .4em;
}

.plus-minus-span {
  cursor: pointer;
  padding: 0 5px;
  text-align: center;
  display: inline-block;
}

.workStyle .header {
    font-weight: bold; 
}

.equipment-type {
    font-size: small;
    font-style: italic;
    margin: 5px 3px 5px 3px;
}

.bold {
    font-weight: bold;
}

.grayBG {
    background-color: #ebebeb;
}

.border-thing {
    border-left: 1px darkslategrey;
}

.green-box {
    border-radius: 20px;
    background-color: #b9debd;
    padding: 20px 15px 20px 15px;
    margin-bottom: 20px;
}

.fc-button-custom {
    margin: 10px 10px 10px 0;
    border-radius: 10px !important;
}

.fc-button-primary {
    background-color: #007ad9;
    border: 1px solid #007ad9;
}

.fc-button-primary:hover {
    background-color: #116fbf;
    color: #ffffff;
    border-color: #116fbf;
}

.fc-button-primary:focus {
    outline: 0 none;
    outline-offset: 0px;
    box-shadow: 0 0 0 0.2em #8dcdff;
}

.fc-button-primary:not(:disabled):active, .fc-button-primary:not(:disabled).fc-button-active {
    color: #fff;
    background-color:  #116fbf;
    border-color:  #116fbf;
}

.hide {
    display: none !important;
}

.show {
    display: block;
}

.show-flex {
    display: flex;
}

.only-icon  .ui-inputtext{
    display: none;
}

.only-icon button.ui-datepicker-trigger.ui-calendar-button  {
    border-radius: 15px !important;
}

body .rescheule-calendar .ui-datepicker-trigger {
    background-color: unset !important;
    border: none !important;
    color: black !important;
}

body .rescheule-calendar .ui-datepicker-trigger .ui-button-text {
    padding: 0!important;
}

body .ui-datepicker-calendar > tbody {
    font-weight: normal !important
}

body .special > .ui-calendar .ui-datepicker {
    left: -140px !important;
}

@media (max-height: 750px) {
    body .billing-dialog .ui-dialog {
        overflow-y: scroll !important;
        max-height: 95%;
    }
} 

.ui-steps ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.center {
    text-align: center;
} 

.urgent {
    border: 2px solid red;
}


.error {
    border: 1px solid red !important;
}

.billing-dialog .ui-dialog-titlebar {
    display: none !important;
}

.no-pad-table-header .ui-table .ui-table-thead > tr > th {
    padding: 0.5rem !important;
    font-size: 13.5px;
}

.backlog-message > .ui-message {
    margin: 0 .5rem 0 .5rem !important;
}

.small-toggle > .ui-inputswitch {
    font-size: 10px !important;
}

.ui-dropdown-label {
    font-weight: bold !important;
}

.ui-table-summary {
    text-align: right !important;
}

.less-top-no-bottom-padding {
    padding-top: 6px !important;
    padding-bottom: 4px !important;
}

.rfq-header {
    font-size: 15px;
}
  
.rfq-subheader {
    font-size: 12px;
}


@media screen and (max-width: 767px) {
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="tel"],
    input[type="password"] {
      font-size: 17px !important;
    }

    body .ui-inputtext {
        font-size: 17px !important;
    }

    input{ touch-action: none !important; }
    textarea{ touch-action: none !important; }
} 

.calendar-picker > span > input {
    border-radius: 5px !important;
}

.no-bullets {
    list-style-type: none;
    margin: 0;
    padding: 0;
}